<template>
  <div class="new-style">
    <div class="col-lg-4 signup-new-left">
      <div class="signin-block-back">
      </div>
      <div class="overlay"></div>
      <div class="d-flex flex-column align-items-center signup-mobile">
        <div class="signup-new-left-logo"></div>
        <div class="col-12 col-lg-9 d-flex flex-lg-row flex-column justify-content-between align-items-center">
          <p class="text">{{ $t('instruction.reg') }}:</p>
          <div style="justify-content: space-between" class="d-flex mob-column-signup">
            <a class="download-btn" :href="$t('instruction.reg_file')" target="_blank"
            >{{ $t('signin-form.download') }}</a>
          </div>
        </div>
        <div class="col-12 col-lg-9">
          <iframe class="instruction-video" src="https://www.youtube.com/embed/UzU6s4U8CXg"
                  frameborder="1"></iframe>
        </div>
      </div>
    </div>
    <div class="col-lg-8 col-12 signup-new-right signup-mobile">
      <div class="signup-wrapper-info">
        <div class="signup-wrapper-info-left">
          <img style="margin-top: auto;margin-bottom: auto" width="74px" height="74px"
               src="/images/exclamation-point.png" alt="">
          <div v-html="$t('signup.student-text')" class="signup-wrapper-info-left-text"></div>
        </div>
      </div>
      <h3 class="signup-h3" v-if="this.$i18n.locale === 'ru'" style=""> Шаг 2 из 2</h3>
      <h3 class="signup-h3" v-if="this.$i18n.locale === 'kz'" style="">2 қадамның 2-сі</h3>

      <div class="row">
        <div class="col-lg-4 col-md-12">
          <div class="signup-requered-top-mob pt-0 pb-4 text-center">
            {{ $t('signup.requered-fields') }}
          </div>
          <h5 class="form-sub-title">{{ $t('profile.education') }}</h5>
          <form-group :field="$v.account.education.region_id"
                      :label="$t('references.required-field') + $t('references.region-label')"
                      name="region_id">
            <el-select
                v-model="account.education.region_id"
                :placeholder="$t('references.region-placeholder')"
                @change="getCities"
                filterable>
              <el-option
                  v-for="item in regions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </form-group>
          <form-group :field="$v.account.education.city_id"
                      :label="$t('references.required-field') + $t('references.district-label')"
                      name="city_id">
            <el-select
                v-model="account.education.city_id"
                :placeholder="$t('references.district-placeholder')"
                @change="getLocalities"
                filterable>
              <el-option
                  v-for="item in cities"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </form-group>
          <form-group :field="$v.account.education.locality_id"
                      :label="$t('references.required-field') + $t('references.locality-label')"
                      name="locality_id">
            <el-select
                @change="getSchools"
                v-model="account.education.locality_id"
                autocomplete="nope"
                :placeholder="$t('references.locality-placeholder')"
                filterable>
              <el-option
                  v-for="item in localities"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </form-group>
          <form-group :field="$v.account.education.school_id"
                      :label="$t('references.required-field') + $t('references.school-name-label')">
            <el-select
                @change="$v.account.education.school_id.$touch()"
                v-model="account.education.school_id"
                :placeholder="$t('references.school-name-placeholder')"
                filterable>
              <el-option
                  v-for="item in schools"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </form-group>
          <form-group :field="$v.account.education.class"
                      :label="$t('references.required-field') + $t('references.class-label')">
            <el-select
                v-model="account.education.class"
                :placeholder="$t('references.class-placeholder')">
              <el-option
                  v-for="item in classes"
                  :key="item.id"
                  :label="item.id + $t('profile.class')"
                  :value="item.id">
              </el-option>
            </el-select>
          </form-group>
          <form-group :field="$v.account.education.instruction_language"
                      :label="$t('references.required-field') + $t('references.education-language-label')">
            <el-select
                v-model="account.education.instruction_language"
                :placeholder="$t('references.education-language-label')">
              <el-option
                  v-for="item in [{
                                                label: $t('references.language-ru'),
                                                value: 'ru'
                                                }, {
                                                label: $t('references.language-kz'),
                                                value: 'kz'
                                                }]"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </form-group>
          <form-group :field="$v.account.education.test_language"
                      :label="$t('references.required-field') + $t('references.test-language-label')">

            <el-select
                v-model="account.education.test_language"
                :placeholder="$t('references.test-language-label')">
              <el-option
                  v-for="item in [{
                                                label: $t('references.language-ru'),
                                                value: 'ru'
                                                }, {
                                                label: $t('references.language-kz'),
                                                value: 'kz'
                                                }]"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </form-group>
        </div>
        <div class="col-lg-4 col-md-12">
          <h5 class="form-sub-title">{{ $t('profile.representative') }}</h5>
          <form-group :field="$v.account.parent.last_name"
                      :label=" $t('profile.representative-last-name-label')"
                      class="first-el-mob">
            <el-input
                @change="$v.account.parent.last_name.$touch()"
                v-model="account.parent.last_name"
                :placeholder="$t('profile.representative-last-name-placeholder')"
            >
            </el-input>
          </form-group>
          <form-group :field="$v.account.parent.first_name"
                      :label="$t('profile.representative-first-name-label')">
            <el-input
                @change="$v.account.parent.first_name.$touch()"
                v-model="account.parent.first_name"
                :placeholder="$t('profile.representative-first-name-placeholder')"
            >
            </el-input>
          </form-group>
          <form-group>
            <label class="form-label">{{ $t('profile.representative-patronymic-label') }}</label>
            <el-input
                v-model="account.parent.patronymic"
                :placeholder="$t('profile.representative-patronymic-placeholder')"
            ></el-input>
          </form-group>


          <form-group
              :field="$v.account.parent.phone"
              :label="$t('profile.representative-tel-label')"
              name="parentPhone"
          >
            <el-input
                @input="enforcePhoneFormat"
                v-model="parentPhone"
                v-mask="'+7 (###) ### ##-##'"
                id="parentPhone"
                :placeholder="$t('profile.representative-tel-placeholder')"
                clearable>
            </el-input>
          </form-group>
        </div>
      </div>
      <div class="row" style="margin-bottom: 100px">
        <div class="form-check pl-0 pt-4">
          <label class="form-check-label" for="all">
            <router-link tag="a" to="/personal-data">
              {{ $t("signup.accept") }}
            </router-link>
            <input class="form-check-input" type="checkbox" name="filter" id="all" value="0" v-model="acceptData">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="col-12 pt-2 px-0">
          <div class="pt-3 signup-submit-block">
            <el-button class="btn-finish " style="margin-right: 10px; background-color: gray"
                       @click="goBack"
            >
              {{ $t('signup.back-btn') }}
            </el-button>
            <el-button class="btn-finish ml-0"
                       :disabled="!acceptData"
                       @click="createUser"
            >
              {{ $t('signup.signup-btn') }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import VueMask from 'v-mask'
import {mapActions} from 'vuex'
import {minLength, minValue, required, email, helpers, sameAs} from "vuelidate/lib/validators";
import CodeInput from "vue-verification-code-input";

const passwordHelper = helpers.regex('passwordHelper', /\d{3}[0-9]/);


export default {
  name: 'Signup',
  beforeCreate() {
    Vue.use(VueMask);
  },
  components: {
    CodeInput,
  },
  data() {
    return {
      acceptData:false,
      parentPhone: '',
      localities: [],
      regions: [],
      cities: [],
      schools: [],
      phoneVerified: false,
      account: {
        education: {
          city_id: '',
          class: '',
          class_for_admission: '',
          id: '',
          instruction_language: '',
          locality_id: '',
          region_id: '',
          school_id: '',
          school_type_id: '',
          test_language: '',
        },
        parent: {
          additional_phone: '',
          created_at: '',
          email: '',
          first_name: '',
          id: '',
          last_name: '',
          patronymic: '',
          phone: '',
          updated_at: '',
          user_id: '',
        },
        iin: '',
        last_name: '',
        first_name: '',
        patronymic: '',
        birth_date: '',
        locality_id: '',
        region_id: '',
        city_id: '',
        phone: '',
        email: '',
        sex: '',
        gender: '',
        password: '',
        confirm_password: '',
      },
      classes: [
        {
          id: 1,
        },
        {
          id: 2,
        },
        {
          id: 3,
        },
        {
          id: 4,
        },
        {
          id: 5,
        },
        {
          id: 6,
        },
        {
          id: 7,
        },
        {
          id: 8,
        },
      ],
    }
  },
  validations: {
    account: {
      parent: {
        email: {email, required: false},
        first_name: {required:false},
        last_name: {required:false},
        phone: {required:false, minLength: minLength(11)},
      },
      education: {
        city_id: {required},
        class: {required},
        instruction_language: {required},
        locality_id: {required},
        region_id: {required},
        school_id: {required},
        test_language: {required},
      },

    },
  },
  mounted() {
    // if (sessionStorage.getItem('phone-verified')) {
    //   this.phoneVerified = sessionStorage.getItem('phone-verified');
    // } else {
    //   this.$router.push('/signup-new')
    // }
    if (sessionStorage.getItem('account')) {
      this.account = JSON.parse(sessionStorage.getItem('account'));

      function format(value, pattern) {
        var i = 0,
            v = value.toString();
        return pattern.replace(/#/g, _ => v[i++]);
      }

      if (this.account.parent.phone) {
        this.parentPhone = format(this.account.parent.phone, '+# (###) ### ##-##');
        this.enforcePhoneFormat()
      }

    }
    this.$http.get(window.API_ROOT + '/api/references/regions')
        .then((res) => {
          this.regions = res.body.data;
        })
    if (this.account.education.region_id) {
      this.$http.get(window.API_ROOT + '/api/references/districts/' + this.account.education.region_id)
          .then((res) => {
            ;
            if (res.body.data) {
              this.cities = res.body.data;
            }
          })
    }
    if (this.account.education.city_id) {

      this.$http.get(window.API_ROOT + '/api/references/localities/' + this.account.education.city_id)
          .then((res) => {
            this.localitiesLoading = false;
            if (res.body.data) {
              this.localities = res.body.data;
            }
          })
    }
    if (this.account.education.locality_id) {
      this.$http.get(window.API_ROOT + '/api/references/schools/' + this.account.education.locality_id)
          .then((res) => {
                this.schools = res.body.data;
              }
          )
    }
  },

  methods: {
    createUser() {
      sessionStorage.setItem('account', JSON.stringify(this.account))
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.account.education.class_for_admission = this.account.education.class + 1
        this.$http.post(window.API_ROOT + '/api/user', this.account)
            .then((res) => {
              this.loading = false;
              if (res.status == 201) {
                Vue.toastr({
                  message: 'Success',
                  description: this.$t('profile.settings.user-create-success'),
                  type: 'success'
                })
                localStorage.setItem('token', res.body.token)
                localStorage.setItem('role', res.body.role)
                sessionStorage.removeItem('account')
                sessionStorage.removeItem('phone-verified')
                this.$http.get(window.API_ROOT + '/api/user/edit')
                    .then((res) => {
                      localStorage.setItem('user', JSON.stringify(res));
                      this.$router.push(this.$route.query.redirect || '/profile')
                    })

              }
            })
      } else {
        Vue.toastr({
          message: 'error',
          description: this.$t('profile.settings.all-data-complete'),
          type: 'error'
        })
      }
    },
    goBack() {
      sessionStorage.setItem('account', JSON.stringify(this.account))
      this.$router.push('/signup')
    },
    enforcePhoneFormat() {
      this.$v.account.parent.phone.$touch()
      let x = this.parentPhone
          .replace(/\D/g, "")
          .match(/(\d{0,1})(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);

      this.account.parent.phone = x[0];
    },
    getCities(id) {
      this.$v.account.education.region_id.$touch();
      this.citiesLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/districts/' + id)
          .then((res) => {
            this.citiesLoading = false;
            if (res.body.data) {
              this.cities = res.body.data;
              this.account.education.city_id = '';
            }
          }).catch((e) => {
        this.citiesLoading = false;
      })
    },
    getLocalities(id) {
      this.$v.account.education.city_id.$touch();
      this.localitiesLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/localities/' + id)
          .then((res) => {
            this.localitiesLoading = false;
            if (res.body.data) {
              this.localities = res.body.data;
              this.account.education.locality_id = '';
            }
          }).catch((e) => {
        this.localitiesLoading = false;
      })
    },
    getSchools(id, reset = true) {
      this.$v.account.education.locality_id.$touch();
      this.schoolsLoading = true;
      this.$http.get(window.API_ROOT + '/api/references/schools/' + id)
          .then((res) => {
                if (reset == true) {
                  this.schools = '';
                  this.account.education.school_id = ''
                }
                this.schools = res.body.data;
              }
          ).catch((e) => {
        this.schoolsLoading = false;
      })
    },
  }
}
</script>

<style>
.signup-wrapper-info-right-text {
  max-width: 363px;
  margin-left: 50px;
  font-size: 14px;
  line-height: 150%;
}

.signup-wrapper-info-border {
  height: 100%;
  margin-left: 85px;
  border-right: 2px solid #18A0FB;;
}

.signup-new-right .signup-wrapper-info-left-text {
  margin-left: 50px;
  font-size: 13px;
  line-height: 150%;

  font-weight: 400;
  font-family: "Roboto", sans-serif;
}

.signup-wrapper-info-right {
  width: 50%;
}

.signup-wrapper-info-left {
  width: 70%;
  display: flex;
}

.signup-wrapper-info {
  margin-top: 65px;
  display: flex;
  flex-wrap: wrap;
}

.toggle-wrapper {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
}

.toggle-title {
  margin-top: 30px;
  color: #18A0FB;
}

.toggle-button {
  user-select: none;
  margin-top: 10px;
  cursor: pointer;
  margin-right: 20px;
  text-align: center;
  width: 200px;
  line-height: 46px;
  font-weight: 500;
  font-size: 13px;
  color: #18A0FB;
  background: #F7F8F9;
  border-radius: 10px;
}

.toggle-active-button {
  user-select: none;
  margin-top: 10px;
  cursor: pointer;
  text-align: center;
  width: 200px;
  line-height: 46px;
  font-weight: 500;
  font-size: 13px;
  color: white;
  background: #18A0FB;
  border-radius: 10px;
}

.margin-download {
  margin-left: 3rem;
}

.download-instruction {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  text-decoration: none;
  max-width: 184px;
  display: block;
  padding: 1px 16px;
  position: static;
  height: 32px;

  font-size: 13px;
  line-height: 32px;
  text-align: center;
  color: #0070D2;
  flex: none;
  order: 1;
  flex-grow: 0;
  background: #FFFFFF;
  border: 1px solid #DDDBDA;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 30px;
}

.download-instruction:hover {
  text-decoration: none;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.el-select {
  margin-bottom: 0;
}

.checkbox-block {
  display: flex;
  flex-direction: row;
}

.form-check-label {
  display: block;
  position: relative;
  padding-left: 26px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-check-label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  border: 1px solid #eee;
  border-radius: 50%;
}

.form-check-label:hover input ~ .checkmark {
  background-color: #eee;
}

.form-check-label input:checked ~ .checkmark {
  background-color: #18A0FB;
}

.form-check-label input:checked .form-check-label {
  background-color: #18A0FB;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.form-check-label input:checked ~ .checkmark:after {
  display: block;
}

.form-check-label .checkmark:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.resend-sms {
  font-size: 16px;
  color: #333;
  display: block;
  margin-top: 20px;
}

.el-button.btn-finish.is-disabled {
  background: #CACACA;
  border: 1px solid #CACACA;
  color: #FFFFFF;
}

.btn-finish {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  background: #18A0FB;
  border-radius: 5px;
  padding: 13px 44px;
  color: #FFFFFF;
  margin: 0;
}

.btn-finish:hover, .btn-finish:focus {
  color: #FFFFFF;
  background: #18A0FB;
}

.signup-requered-top-mob {
  display: none;
}

.signup-accept-block {
  display: flex;
  flex-direction: column;
}

.signup-submit-block {
  display: flex;
  justify-content: left;
}

.gender-mob {
  display: none;
}

@media (max-width: 991px) {
  .signin-form {
    box-shadow: 0px 4px 4px rgb(51 51 51 / 4%), 0px 4px 16px rgb(51 51 51 / 8%);
    border-radius: 8px;
    padding: 25px 21px;
    background: #fff;
  }

  .signup-requered-top {
    display: none;
  }

  .signup-requered-top-mob {
    display: block;
  }

  .signup-accept-block {
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 16px;
  }

  .signup-requered-fields {
    margin-top: 16px;
  }

  .signup-submit-block {
    justify-content: center;
  }

  .gender-mob {
    display: none;
  }

  .gender-mob .el-radio-group {
    text-align: center;
  }

  .gender-mob .el-radio-button__inner {
    padding: 10px 31px;

  }

  .gender-mob .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: #18A0FB;
  }

  /* .mob-hidden {
    display: none;
  } */
}

@media (max-width: 768px) {
  .btn-block {
    text-align: center;
  }
}

@media (max-width: 430px) {
  .margin-download {
    margin-left: 0;
  }

  .download-instruction {
    max-width: 100%;
  }

  .mob-column-signup {
    flex-direction: column !important
  }
}

@media (max-width: 499px) {
  .toggle-button {
    width: 100%;

  }

  .toggle-active-button {
    width: 100%;
  }
}

@media (max-width: 991px) {
  .signup-h3{
    margin-top: 2rem!important;
    margin-bottom: 2rem!important;
    text-align: center;
  }
  .signup-wrapper-info {
    flex-direction: column;
  }

  .toggle-wrapper {
    justify-content: center;
  }

  .signup-wrapper-info-left-text {
    max-width: unset;
  }

  .signup-wrapper-info-left {
    width: 100%
  }

  .signup-wrapper-info-right {
    width: 100%;
  }

  .signup-wrapper-info-right-text {
    margin-left: 123px;
    margin-top: 2rem;
    max-width: unset;
  }

  .signup-wrapper-info-border {
    display: none;
  }
}

@media (max-width: 992px) {
  .signup-new-left .overlay {
    display: none;
  }

  .signup-new-left .signin-block-back {
    display: none;
  }

  .new-style {
    flex-direction: column;
  }

  .signup-new-left {
    padding-top: 40px;
  }

  .signup-new-right {
    padding-left: 15px;
  }

  .signup-new-right .signup-wrapper-info-left-text {
    margin-left: 20px;
  }

  .signup-new-left .text {
    color: #343F68;
    width: 100%;
  }

  .signup-new-left .download-btn {
    padding: 5px 10px;
    color: #FFFFFF;
    background-color: #18A0FB;
    width: 100%;
  }

  .mob-column-signup {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .signup-new-left-logo {
    background-image: url("../../../public/images/Qabilet-Final-footer-light.svg");
    margin-top: 20px;
  }
}

@media (min-width: 576px) {
  .signup-mobile {
    max-width: 540px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 768px) {
  .signup-mobile {
    max-width: 720px;
    margin-right: auto;
    margin-left: auto;

  }
}

@media (min-width: 992px) {
  .signup-mobile {
    max-width: none;
  }
}
</style>